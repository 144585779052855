import React, { useState, useRef } from 'react';
import { navigate } from "gatsby"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { animateScroll as scroll } from "react-scroll"
import * as Yup from "yup";
import * as styles from './EntryForm.module.scss'
//  import axios from "axios";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
//バリデーション
const ContactFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'お名前が短すぎます')
    .max(70, 'お名前が長すぎます')
    .required('お名前は必須項目です'),
  furigana: Yup.string()
    .min(2, 'フリガナが短すぎます')
    .max(70, 'フリガナが長すぎます')
    .required('フリガナは必須項目です'),
  email: Yup.string()
    .email('メールアドレスが正しくありません。')
    .required('メールアドレスは必須項目です'),
  emailconfirm: Yup.string()
    .email('メールアドレスが正しくありません。')
    .oneOf([Yup.ref('email')], 'メールアドレスが一致しません。')
    .required('メールアドレスの確認は必須項目です'),
  address: Yup.string()
    .min(2, 'ご住所が短すぎます')
    .required('ご住所は必須項目です'),
  tel: Yup.string()
    .matches(phoneRegExp, '電話番号が正しくありません')
    .required('電話番号は必須項目です'),
  age: Yup.string()
    .required('年齢は必須項目です'),
  education: Yup.string()
    .min(2, '最終学歴が短すぎます')
    .max(70, '最終学歴が長すぎます')
    .required('最終学歴は必須項目です'),
});


const EntryForm = () => {
  const [toggleState, setToggle] = useState(false);
  const myRef = useRef(null)
  const handleClick = () => {
    setToggle(!toggleState);
    // scroll.scrollToTop();
    var scrlPos = myRef.current.offsetTop;
    scroll.scrollTo(scrlPos);
    // myRef.current.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    // console.log(myRef);
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }



  return (
    <div className={`${styles.EntryForm} ${toggleState ? styles.confirm : ""}`} ref={myRef}>
      <Formik
        initialValues={{
          'form-name': 'EntryForm', // NetlifyFormで必要な値
          'subject': '【お問い合わせがありました】',
          name: '',
          furigana: '',
          email: '',
          emailconfirm: '',
          address: '',
          tel: '',
          message: '',
          'bot-field': '', // NetlifyFormで必要な値
        }}
        validationSchema={ContactFormSchema}
        //  onSubmit={values => {
        //    // same shape as initial values
        //    console.log(values);
        //  }}
        //  onSubmit={handleSubmit}
        onSubmit={
          (values, actions) => {
            fetch("/", {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: encode({ "form-name": "EntryForm", ...values })
            })
              .then(() => {
                // alert('Success');
                navigate('/successentry');
                actions.resetForm()
              })
              .catch(() => {
                alert('Error');
              })
              .finally(() => actions.setSubmitting(false))
          }
        }
      >
        {({ errors, touched }) => (
          <Form id="EntryForm" data-netlify="true">
            <Field type="hidden" name="bot-field" /> {/* NetlifyFormで必要な値 */}
            <Field type="hidden" name="form-name" /> {/* NetlifyFormで必要な値 */}
            <Field type="hidden" name="subject" value={`【お問い合わせがありました】`} /> {/* NetlifyFormで必要な値 */}
            <div className={styles.EntryForm__item}>
              <label htmlFor="name">
                <span className={styles.EntryForm__title}>お名前</span>
                <Field name="name" className={styles.EntryForm__field} placeholder="山口　太郎" />
                {errors.name && touched.name ? <div className={styles.EntryForm__errormsg}>{errors.name}</div> : null}
              </label>
            </div>
            <div className={styles.EntryForm__item}>
              <label htmlFor="furigana">
                <span className={styles.EntryForm__title}>フリガナ</span>
                <Field name="furigana" className={styles.EntryForm__field} placeholder="ヤマグチ　タロウ" />
                {errors.furigana && touched.furigana ? <div className={styles.EntryForm__errormsg}>{errors.furigana}</div> : null}
              </label>
            </div>
            <div className={styles.EntryForm__item}>
              <label htmlFor="email">
                <span className={styles.EntryForm__title}>メールアドレス</span>
                <Field name="email" type="email" className={styles.EntryForm__field} placeholder="xxxx_1234@xxx.com" />
                {errors.email && touched.email ? <div className={styles.EntryForm__errormsg}>{errors.email}</div> : null}
              </label>
            </div>
            <div className={styles.EntryForm__item}>
              <label htmlFor="emailconfirm">
                <span className={styles.EntryForm__title}>メールアドレス（確認）</span>
                <Field name="emailconfirm" type="email" className={styles.EntryForm__field} placeholder="xxxx_1234@xxx.com" />
                {errors.emailconfirm && touched.emailconfirm ? <div className={styles.EntryForm__errormsg}>{errors.emailconfirm}</div> : null}
              </label>
            </div>
            <div className={styles.EntryForm__item}>
              <label htmlFor="address">
                <span className={styles.EntryForm__title}>ご住所</span>
                <Field name="address" className={styles.EntryForm__field} placeholder="ささみ市黒かしわ町1番1号" />
                {errors.address && touched.address ? <div className={styles.EntryForm__errormsg}>{errors.address}</div> : null}
              </label>
            </div>
            <div className={styles.EntryForm__item}>
              <label htmlFor="tel">
                <span className={styles.EntryForm__title}>電話番号</span>
                <Field name="tel" type="tel" className={styles.EntryForm__field} placeholder="000-000-0000" />
                {errors.tel && touched.tel ? <div className={styles.EntryForm__errormsg}>{errors.tel}</div> : null}
              </label>
            </div>
            <div className={styles.EntryForm__item}>
              <label htmlFor="age">
                <span className={styles.EntryForm__title}>年齢</span>
                <Field name="age" type="number" className={styles.EntryForm__field} placeholder="22" />
                {errors.age && touched.age ? <div className={styles.EntryForm__errormsg}>{errors.age}</div> : null}
              </label>
            </div>
            <div className={styles.EntryForm__item}>
              <label htmlFor="education">
                <span className={styles.EntryForm__title}>最終学歴</span>
                <Field name="education" className={styles.EntryForm__field} placeholder="深川学院大学" />
                {errors.education && touched.education ? <div className={styles.EntryForm__errormsg}>{errors.education}</div> : null}
              </label>
            </div>
            <div className={styles.EntryForm__item}>
              <label htmlFor="major">
                <span className={styles.EntryForm__title}>学校名・学部・学科</span>
                <Field name="major" className={styles.EntryForm__field} placeholder="深川学院大学・チキン学部・チキン学科" />
                {errors.major && touched.major ? <div className={styles.EntryForm__errormsg}>{errors.major}</div> : null}
              </label>
            </div>
            <div className={styles.EntryForm__item}>
              <span className={styles.EntryForm__title}>採用内容</span>
              <ul className={`${styles.EntryForm__labels} ${styles.employ}`}>
                <li>
                  <input name="employ" id="employ1" type="radio" defaultChecked className={styles.EntryForm__field} />
                  <label htmlFor="employ1" className={styles.radio01}><span>新卒</span></label>
                </li>
                <li>
                  <input name="employ" id="employ2" type="radio" className={styles.EntryForm__field} />
                  <label htmlFor="employ2" className={styles.radio01}><span>中途</span></label>
                </li>
              </ul>
              {/* {errors.employ && touched.employ ? <div className={styles.EntryForm__errormsg}>{errors.employ}</div> : null} */}
            </div>
            <div className={styles.EntryForm__item}>
              <span className={`${styles.EntryForm__title} ${styles.noafter}`}>ご希望の部署（複数回答可）</span>
              <ul className={`${styles.EntryForm__labels} ${styles.department}`}>
                <li>
                  <Field name="department1" id="department1" type="checkbox" className={styles.EntryForm__field} />
                  <label htmlFor="department1" className={styles.checkbox01}><span>事務系・技術系（製造部門）</span></label>
                </li>
                <li>
                  <Field name="department2" id="department2" type="checkbox" className={styles.EntryForm__field} />
                  <label htmlFor="department2" className={styles.checkbox01}><span>生産系（鶏管理）</span></label>
                </li>
                <li>
                  <Field name="department3" id="department3" type="checkbox" className={styles.EntryForm__field} />
                  <label htmlFor="department3" className={styles.checkbox01}><span>営業販売系</span></label>
                </li>
              </ul>
              {/* {errors.department1 && touched.department1 ? <div className={styles.EntryForm__errormsg}>{errors.department1}</div> : null} */}
            </div>
            <div className={styles.EntryForm__item}>
              <span className={`${styles.EntryForm__title} ${styles.noafter}`}>採用情報の入手先（複数回答可）</span>
              <ul className={`${styles.EntryForm__labels} ${styles.media}`}>
                <li>
                  <Field name="media1" id="media1" type="checkbox" className={styles.EntryForm__field} />
                  <label htmlFor="media1" className={styles.checkbox01}><span>ハローワーク</span></label>
                </li>
                <li>
                  <Field name="media2" id="media2" type="checkbox" className={styles.EntryForm__field} />
                  <label htmlFor="media2" className={styles.checkbox01}><span>検索エンジン</span></label>
                </li>
                <li>
                  <Field name="media3" id="media3" type="checkbox" className={styles.EntryForm__field} />
                  <label htmlFor="media3" className={styles.checkbox01}><span>求人情報サイト</span></label>
                </li>
                <li>
                  <Field name="media4" id="media4" type="checkbox" className={styles.EntryForm__field} />
                  <label htmlFor="media4" className={styles.checkbox01}><span>求人誌</span></label>
                </li>
                <li>
                  <Field name="media5" id="media5" type="checkbox" className={styles.EntryForm__field} />
                  <label htmlFor="media5" className={styles.checkbox01}><span>その他</span></label>
                </li>
              </ul>
              {/* {errors.department1 && touched.department1 ? <div className={styles.EntryForm__errormsg}>{errors.department1}</div> : null} */}
            </div>
            <div className={styles.EntryForm__item}>
              <div className={`${styles.EntryForm__title} ${styles.noafter}`}>自己PR・ご質問等</div>
              <Field name="message" component="textarea" className={styles.EntryForm__textarea} placeholder="自己PR・ご質問等ございましたらご記入ください"></Field>
            </div>
            <div className={styles.EntryForm__errormsg}>
              <div>
                <ErrorMessage name="name" />
              </div>
              <div>
                <ErrorMessage name="furigana" />
              </div>
              <div>
                <ErrorMessage name="email" />
              </div>
              <div>
                <ErrorMessage name="emailconfirm" />
              </div>
              <div>
                <ErrorMessage name="address" />
              </div>
              <div>
                <ErrorMessage name="tel" />
              </div>
              <div>
                <ErrorMessage name="age" />
              </div>
              <div>
                <ErrorMessage name="education" />
              </div>
              <div>
                <ErrorMessage name="major" />
              </div>
            </div>
            {
              toggleState ?
                <div className={`${styles.EntryForm__btnList}`}>
                  <button type="button" className={`${styles.EntryForm__btn} ${styles.grayBtn}`} onClick={() => handleClick()}><span>修正</span></button>
                  <button type="submit" className={styles.EntryForm__btn}><span>送信</span></button>
                </div>

                :

                <div className={`${styles.EntryForm__btnList}`}>
                  <button type="button" className={styles.EntryForm__btn} onClick={() => handleClick()}><span>入力内容確認</span></button>
                </div>

            }
          </Form>
        )}
      </Formik>
    </div>
  )
};

export default EntryForm