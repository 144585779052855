import React from "react"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import * as styles from "../styles/Entry.module.scss";
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { RecruitLayout, RecruitLowerHeaderBg } from "../components/Recruit/index";
import { LowerHeader } from "../components/LowerPages/index"
import EntryForm from "../components/Forms/EntryForm.jsx"

const Entry = () => {
  const breakpoints = useBreakpoint();
  const title1 = [
    { "id": 0, "text": "e" },
    { "id": 1, "text": "n" },
    { "id": 2, "text": "t" },
    { "id": 3, "text": "r" },
    { "id": 4, "text": "y" }
  ];
  return (
    <RecruitLayout className={`${styles.Entry}`} pageName={`entry`} title={`エントリー`}>
      <Seo
        title={`エントリー`}
        description={``}
      />
      <div className={`LowerHeadImg`}>
        {
          breakpoints.sm ?
            <StaticImage
              layout="fullWidth"
              src="../images/recruit/entry/entry_header-sp@2x.png"
              alt="entry_header"
            />
            :
            <StaticImage
              layout="fullWidth"
              src="../images/recruit/entry/entry_header@2x.png"
              alt="entry_header"
            />

        }
      </div>
      <div className={`LowerWrapper`}>
        <LowerHeader data={title1} title={`エントリー`}>
          <RecruitLowerHeaderBg />
        </LowerHeader>
        <div className={`LowerContent`}>
          <div className={`LowerContent__inner`}>
            <div className={`LowerLead`}>
              <p>
                弊社への採用エントリーは下記のメールフォームをご利用ください。<br />
                申込者からいただいた個人情報は、慎重に取り扱い、<br />
                申込者の同意なしに第三者に提供または開示をすることはありません。<br />
                個人情報保護方針については<a href="/privacy/">こちら</a>をご覧ください。
              </p>
            </div>
            <EntryForm />
          </div>
        </div>
      </div>
    </RecruitLayout>
  )
}
export default Entry