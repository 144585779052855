// extracted by mini-css-extract-plugin
export var EntryForm = "EntryForm-module--EntryForm--1YnyS";
export var EntryForm__errormsg = "EntryForm-module--EntryForm__errormsg--1kNP9";
export var EntryForm__item = "EntryForm-module--EntryForm__item--2RKwZ";
export var EntryForm__title = "EntryForm-module--EntryForm__title--2ghWN";
export var noafter = "EntryForm-module--noafter--1p62r";
export var EntryForm__field = "EntryForm-module--EntryForm__field--1PWwS";
export var EntryForm__textarea = "EntryForm-module--EntryForm__textarea--1VsbF";
export var EntryForm__btnList = "EntryForm-module--EntryForm__btnList--oesp9";
export var grayBtn = "EntryForm-module--grayBtn--vMXHZ";
export var EntryForm__btn = "EntryForm-module--EntryForm__btn--28rvY";
export var EntryForm__labels = "EntryForm-module--EntryForm__labels--1PEPM";
export var employ = "EntryForm-module--employ--bSGdR";
export var radio01 = "EntryForm-module--radio01--1FLLg";
export var department = "EntryForm-module--department--4sLyI";
export var media = "EntryForm-module--media--2xUw8";
export var checkbox01 = "EntryForm-module--checkbox01--Rf9Pe";
export var confirm = "EntryForm-module--confirm--2S3oZ";